import dayjs from "dayjs";

/**검색조건 param 설정
 * @param {Object} searchCondition - 검색조건.
 * @description size는 7로 고정. 임상갤러리만 8로 설정.
 */
export const changeParam = (searchCondition, thisPage) => {
  const { page, sort, startDate, endDate, tabFilter, ...rest } = searchCondition;

  const q = filterQ(rest);

  if (startDate && endDate) {
    q.push(`createdDate:${dayjs(startDate).format("YYYY-MM-DD")}_${dayjs(endDate).format("YYYY-MM-DD")}`);
  }

  return {
    q: q.join(","),
    page: page - 1,
    size: thisPage === "gallery" ? 8 : 7,
    sort: sort,
  };
};

/**
 * 게시판 검색조건 param
 * @param {Object} searchCondition - 검색조건.
 * @description size는 10으로 고정.
 */
export const changeBoardParam = (searchCondition) => {
  const { page, sort, searchSelect, searchText, searchStart, searchEnd, ...rest } = searchCondition;

  const q = filterQ(rest);

  if (!!searchText) {
    q.push(`${searchSelect}:${searchText}`);
  }

  if (searchStart && searchEnd) {
    q.push(`startDate:${dayjs(searchStart).format("YYYY-MM-DD")}_${dayjs(searchEnd).format("YYYY-MM-DD")}`);
  }

  return {
    q: q.join(","),
    page: page - 1,
    size: 10,
    sort: sort,
  };
};

/**영어 대소문자, 한글만 입력 처리
 * @param {string} input - 입력값.
 * @returns {string} - 필터링된 입력값.
 */
export const replaceInput = (input) => {
  return input.replace(/[^a-zA-Z가-힣]/g, "");
};

/**q 관련 검색어 필터링(일반 검색)
 * @param {Object} rest - 검색어들
 * @returns {Array} - 검색어 배열.
 */
export const filterQ = (rest) => {
  return Object.entries(rest)
    .filter(([, value]) => value !== "")
    .map(([key, value]) => `${key}:${value}`);
};

/**
 * 날짜 변경 처리
 * @param {Date} date - 선택된 날짜.
 * @param {function} setValue - form set 함수.
 * @param {string} fieldName - 필드 이름.
 * @returns {void}
 */
export const onDateChange = (date, setValue, fieldName) => {
  if (date) {
    setValue(fieldName, dayjs(date).format("YYYY-MM-DD HH:mm:ss"));
  } else {
    setValue(fieldName, "");
  }
};
