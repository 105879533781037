import { Form, Formik } from "formik";
import Notiflix from "notiflix";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import * as Yup from "yup";
import { useSaveFileStep } from "../../../../../apis/studyApi";
import { meditScan } from "../../../../../atoms/meditAtom";
import { userInfo } from "../../../../../atoms/userAtom";
import { CardTail, CardTitle } from "../../../../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../../../../components/Layout/CardLayout/style/CardLayout.style";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../../../components/element/FieldMessage/FieldMessage";
import { ScanModelModal } from "../../../../../components/element/ScanPreview/ScanModelModal";
import { ScanPreview } from "../../../../../components/element/ScanPreview/ScanPreview";
import { IK } from "../../../../../utils/i18n/keyStore";
import { studyFormConfig } from "../../../../../utils/studyUtils";

function Scan({ next, prev, currentStepType, stepLabel, currentStepList, studyInfo, setStudyInfo, patientId, isFinish, setCurrentStepIndex }) {
  const { t } = useTranslation(["translation"]);
  const saveFileMutation = useSaveFileStep();

  /**사용자 정보 가져오기 */
  const { countryCode } = useRecoilValue(userInfo);

  const [modalOpen, setModalOpen] = useState(false);
  const [deleteStudyFileIds, setDeleteStudyFileIds] = useState([]);
  /**요약인지 다음인지 구분 */
  const [skip, setSkip] = useState(false);

  const [meditFiles, setMeditFiles] = useRecoilState(meditScan);

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
    setDeliveryStaus({
      isSend: "",
      contact: "",
    });
  };

  const validationSchema = Yup.lazy(() => {
    return Yup.object().shape({
      scan: meditFiles.length > 0 ? Yup.array() : Yup.array().min(1, t(IK.scan_file_message)),
    });
  });

  const handleSubmit = (values) => {
    const formData = new FormData();

    let formDataLength = 0;
    values.scan.forEach((scan) => {
      if (!scan.studyFileId) {
        formData.append("scan", scan);
        formDataLength++;
      }
    });

    if (meditFiles.length > 0 || formDataLength > 0 || deleteStudyFileIds.length > 0) {
      Notiflix.Loading.standard("");

      if (meditFiles.length > 0) {
        formData.append("meditFiles", JSON.stringify(meditFiles));
      }

      if (deleteStudyFileIds.length > 0) {
        formData.append("deleteStudyFileId", deleteStudyFileIds.join(","));
      }

      formData.append("studyPackagesCode", studyInfo.packages);

      saveFileMutation.mutate(
        {
          step: "scan",
          formData,
          params: {
            studyId: studyInfo.studyId,
            patientId: patientId,
            submitTypeCode: "NOW",
          },
        },
        {
          onSuccess: () => {
            setDeleteStudyFileIds([]);
            setMeditFiles([]);
            navigateNext();
            Notiflix.Loading.remove();
          },
          onError: () => {
            Notiflix.Report.failure(t(IK.file_faile), t(IK.file_faile_check), t(IK.confirm));
            Notiflix.Loading.remove();
          },
        }
      );
    } else {
      // 파일이 없을 경우 다음 단계로 이동
      return navigateNext();
    }
  };

  /**다음단계로 이동 */
  const navigateNext = () => {
    if (isFinish && skip) {
      setCurrentStepIndex(currentStepList.length - 2);
    } else {
      next();
    }
  };

  const config = studyFormConfig[currentStepType][stepLabel.toLowerCase()];

  /**택배희망접수 여부 */
  const [deliveryStatus, setDeliveryStaus] = useState({
    isSend: "",
    contact: "",
  });
  const [errorDelivery, setErrorDelivery] = useState("");
  const [errorDeliveryNumber, setErrorDeliveryNumber] = useState("");

  /**석고모델 보내기 */
  const handlePlasterModelSubmit = () => {
    //택배희망접수 여부 체크
    if (deliveryStatus.isSend === "") {
      return setErrorDelivery("address_delivery_check_message");
    }
    //택배희망 연락처 체크
    if (deliveryStatus.isSend === "true" && deliveryStatus.contact === "") {
      return setErrorDeliveryNumber("number_required");
    }

    Notiflix.Loading.standard("");

    setStudyInfo({ ...studyInfo, scan: [] });
    setMeditFiles([]);

    //파일 데이터 추가
    const formData = new FormData();
    formData.append("studyPackagesCode", studyInfo.packages);
    formData.append("isDelivery", deliveryStatus.isSend);
    formData.append("phoneNumber", deliveryStatus.contact);

    saveFileMutation.mutate(
      {
        step: "scan",
        formData,
        params: {
          studyId: studyInfo.studyId,
          patientId: patientId,
          submitTypeCode: "MODEL",
        },
      },
      {
        onSuccess: () => {
          setStudyInfo({ ...studyInfo, scan: [], meditFiles: [] });
          setMeditFiles([]);
          Notiflix.Loading.remove();
          navigateNext();
        },
        onError: (error) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(t(IK.again_message));
        },
      }
    );
  };

  return (
    <>
      <CardTitle title={studyInfo.patientName} subTitle={`${t(IK[studyInfo.packages])}`} />
      <Formik
        initialValues={{
          scan: studyInfo.scan || [],
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <ScanPreview setFieldValue={setFieldValue} setDeleteStudyFileIds={setDeleteStudyFileIds} files={values.scan} meditFiles={meditFiles} setMeditFiles={setMeditFiles} />
            {meditFiles.length === 0 && <FieldMessageErrorClick name="scan" />}
            <CardTail line study>
              <S2.CardFlex>
                <S.StyledButton $secondary as="button" type="button" onClick={() => setCurrentStepIndex(1)}>
                  {t(IK.select_treatment_options)}
                </S.StyledButton>
                <S.ButtonLtBox>
                  <S.StyledButton as="button" type="button" onClick={prev}>
                    {t(IK.prev)}
                  </S.StyledButton>
                  {config.send_model && countryCode === "01" && (
                    <S.StyledButton as="button" $primary type="button" onClick={openModal}>
                      {t(IK.send_model)}
                    </S.StyledButton>
                  )}
                  <S.StyledButton as="button" $primary type="submit">
                    {t(IK.next)}
                  </S.StyledButton>
                  {isFinish && (
                    <S.StyledButton as="button" $primary type="submit" onClick={() => setSkip(true)}>
                      {t(IK.Skip_to_prescription_summary)}
                    </S.StyledButton>
                  )}
                </S.ButtonLtBox>
              </S2.CardFlex>
            </CardTail>
            <ScanModelModal
              modalOpen={modalOpen}
              closeModal={closeModal}
              handlePlasterModelSubmit={handlePlasterModelSubmit}
              deliveryStatus={deliveryStatus}
              setDeliveryStaus={setDeliveryStaus}
              errorDelivery={errorDelivery}
              setErrorDelivery={setErrorDelivery}
              errorDeliveryNumber={errorDeliveryNumber}
              setErrorDeliveryNumber={setErrorDeliveryNumber}
            />
          </Form>
        )}
      </Formik>
    </>
  );
}

export default Scan;
